<template>
  <section class="page-cyprus">
    <div class="banner">
      <img
        class="logo"
        src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/cyprus-logo.png"
      />
      <p class="title">
        <span>JWP AIF V.C.I.C. PLC </span>
      </p>
    </div>
    <div class="content" :class="{ fold: isFold }">
      <p>
        JWP Group has accumulated more than 10 years of investment and
        development experience in the Cyprus real estate market, and the Cyprus
        project has been one of the company's pillar businesses since 2008. JWP
        Group has developed over 30 successful real estate projects in all major
        cities of Cyprus, such as Larnaca, Limassol, Nicosia, etc. In addition,
        its investment areas also cover a wide range of assets, from high-end
        residential to large commercial real estate: luxury apartment
        residences, designer villas, high-end student apartments, luxury hotels
        and resorts. In order to meet market demand and enrich product levels,
        JWP Group launched the second JWP fund-JWP AIF V.C.I.C. PLC. Committed
        to investing in the growth and development of the Cyprus economy, JWP
        fund not only provides attractive investment products for
        ultra-high-net-worth investors, but is also regulated by the Cyprus
        Securities and Exchange Commission (CySEC) and the European Central Bank
        (ECB). The fund has formed an elite financial team with solid research,
        analysis, investment and management capabilities and its investment
        activities will also benefit from JWP Group's rich local resources,
        experience and professionalism.
      </p>
    </div>
    <div class="look-more">
      <div class="opacity" v-if="isFold">
       
      </div>
      <div class="btn" @click="handleLookMore">
        <span>{{ btnText }}</span>
        <img
          :src="
            isFold
              ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
              : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
          "
        />
      </div>
    </div>
    <div class="approval">
      <p class="hd">Fund Certificate issued by CySEC</p>
      <ul class="boxs">
        <li class="box">
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/approval-1.jpg"
          />
        </li>
        <li class="box">
          <van-image
            fit="cover"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/approval-2.jpg"
          />
        </li>
      </ul>
    </div>
    <div class="strategy">
      <p class="title">
        <span class="van-hairline--bottom">Investment Strategy</span>
      </p>
      <p class="hd">
        We use our wealth of real estate development experience to create value
        for our investors
      </p>
      <div class="scroll-wrap">
        <ul class="boxs">
          <li class="box">
            <div class="inner">
              <van-image
                width="32"
                height="26"
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-1.png"
              />
              <p class="desc">Flexible investment tools</p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="32"
                height="26"
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-2.png"
              />
              <p class="desc">Strict selection criteria</p>
            </div>
          </li>
          <li class="box">
            <div class="inner">
              <van-image
                width="32"
                height="26"
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-3.png"
              />
              <p class="desc">Clear withdrawal strategy</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="features">
      <p class="title">
        <span class="van-hairline--bottom">Fund Features</span>
      </p>
      <van-image
        lazy-load
        src="//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/features-en.jpg"
      />
    </div>
    <div class="advantages">
      <p class="title">
        <span class="van-hairline--bottom">JWP Fund Advantages</span>
      </p>
      <ul class="boxs">
        <li class="box">
          <p class="hd">Transparency</p>
          <p class="desc">
            <span>Product information is clear</span>
            <span>Regulated by the</span>
            <span>ECB and CySEC， with complete</span>
            <span>licenses, regular audit and</span>
            <span>disclosure of investment</span>
          </p>
        </li>
        <li class="box">
          <p class="hd">Less fees</p>
          <p class="desc">
            <span>No ownership cost</span>
            <span>No income tax</span>
            <span>No additional maintenance cost</span>
          </p>
        </li>
        <li class="box">
          <p class="hd">Spread risk</p>
          <p class="desc">
            <span>Diversified allocation of geographical</span>
            <span>location and asset types</span>
            <span>Risk resistance</span>
          </p>
        </li>
        <li class="box">
          <p class="hd">Professional investment system</p>
          <p class="desc">
            <span>Senior team makes more professional </span>
            <span>judgment for investment</span>
            <span>Make plans for withdrawal path</span>
            <span>simultaneously at investment stage</span>
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View more"
    };
  },
  beforeCreate() {
    document.title = "Fund in Cyprus";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-cyprus {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/banner.jpg")
      no-repeat center center / cover;
    .logo {
      width: 56px;
    }
    .title {
      margin-top: 20px;
      padding-bottom: 36px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
  }
  .content {
    padding: 16px 22px 0;
    background: #fafafa;
    transition: all 0.3s;
    &.fold {
      height: 71px;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
    }
  }
  .look-more {
      padding: 20px 18px 20px;
      background: #fafafa;
      z-index: 10;
      .opacity {
        position: absolute;
        height: 20px;
        width: calc(100% - 36px);
        margin: -40px 0 0px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #fafafa
        );
        z-index: 10;
        
      }
      .btn {
        margin: 0px 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        border-radius: 4px;
        border: 1px solid rgba(38, 38, 38, 1);
        &:active {
          opacity: 0.8;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
        }
        img {
          width: 8px;
          margin-left: 8px;
        }
      }
    }
  .approval {
    width: 100vw;
    padding-top: 20px;
    overflow-x: scroll;
    .hd {
      padding: 0 0 14px 22px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      padding-left: 22px;
      .box {
        padding-right: 15px;
        &:last-child {
          padding-right: 22px;
        }
        .van-image {
          width: 149px;
          height: 103px;
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid rgba(55, 55, 55, 1);
        }
      }
    }
  }
  .strategy {
    padding-top: 40px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .hd {
      padding: 0 0 29px 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
    }
    .scroll-wrap {
      width: 100vw;
      overflow-x: scroll;
      .boxs {
        display: flex;
        justify-content: space-between;
        padding: 0 22px;
        .box {
          flex-shrink: 0;
          overflow-y: hidden;
          &:last-child {
            padding-right: 22px;
          }
          .inner {
            text-align: center;
            .kw {
              font-size: 16px;
              font-weight: bold;
              color: rgba(0, 61, 125, 1);
              line-height: 22px;
            }
            .desc {
              max-width: 110px;
              margin-top: 10px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(38, 38, 38, 1);
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  .features {
    padding: 40px 22px 0;
    text-align: center;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
  }
  .advantages {
    padding: 40px 0 32px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .boxs {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0 22px;
      .box {
        width: 154px;
        min-height: 144px;
        margin-bottom: 18px;
        padding: 15px 10px 15px 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 61, 125, 1);
        &:nth-child(2n + 2) {
          margin-left: 10px;
        }
        .hd {
          font-size: 16px;
          font-weight: bold;
          color: rgba(0, 61, 125, 1);
        }
        .desc {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          span {
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
            color: rgba(38, 38, 38, 1);
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
