var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-cyprus"},[_vm._m(0),_c('div',{staticClass:"content",class:{ fold: _vm.isFold }},[_c('p',[_vm._v(" JWP Group has accumulated more than 10 years of investment and development experience in the Cyprus real estate market, and the Cyprus project has been one of the company's pillar businesses since 2008. JWP Group has developed over 30 successful real estate projects in all major cities of Cyprus, such as Larnaca, Limassol, Nicosia, etc. In addition, its investment areas also cover a wide range of assets, from high-end residential to large commercial real estate: luxury apartment residences, designer villas, high-end student apartments, luxury hotels and resorts. In order to meet market demand and enrich product levels, JWP Group launched the second JWP fund-JWP AIF V.C.I.C. PLC. Committed to investing in the growth and development of the Cyprus economy, JWP fund not only provides attractive investment products for ultra-high-net-worth investors, but is also regulated by the Cyprus Securities and Exchange Commission (CySEC) and the European Central Bank (ECB). The fund has formed an elite financial team with solid research, analysis, investment and management capabilities and its investment activities will also benefit from JWP Group's rich local resources, experience and professionalism. ")])]),_c('div',{staticClass:"look-more"},[(_vm.isFold)?_c('div',{staticClass:"opacity"}):_vm._e(),_c('div',{staticClass:"btn",on:{"click":_vm.handleLookMore}},[_c('span',[_vm._v(_vm._s(_vm.btnText))]),_c('img',{attrs:{"src":_vm.isFold
            ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
            : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'}})])]),_c('div',{staticClass:"approval"},[_c('p',{staticClass:"hd"},[_vm._v("Fund Certificate issued by CySEC")]),_c('ul',{staticClass:"boxs"},[_c('li',{staticClass:"box"},[_c('van-image',{attrs:{"fit":"cover","lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/approval-1.jpg"}})],1),_c('li',{staticClass:"box"},[_c('van-image',{attrs:{"fit":"cover","lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/approval-2.jpg"}})],1)])]),_c('div',{staticClass:"strategy"},[_vm._m(1),_c('p',{staticClass:"hd"},[_vm._v(" We use our wealth of real estate development experience to create value for our investors ")]),_c('div',{staticClass:"scroll-wrap"},[_c('ul',{staticClass:"boxs"},[_c('li',{staticClass:"box"},[_c('div',{staticClass:"inner"},[_c('van-image',{attrs:{"width":"32","height":"26","lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-1.png"}}),_c('p',{staticClass:"desc"},[_vm._v("Flexible investment tools")])],1)]),_c('li',{staticClass:"box"},[_c('div',{staticClass:"inner"},[_c('van-image',{attrs:{"width":"32","height":"26","lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-2.png"}}),_c('p',{staticClass:"desc"},[_vm._v("Strict selection criteria")])],1)]),_c('li',{staticClass:"box"},[_c('div',{staticClass:"inner"},[_c('van-image',{attrs:{"width":"32","height":"26","lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/icon-3.png"}}),_c('p',{staticClass:"desc"},[_vm._v("Clear withdrawal strategy")])],1)])])])]),_c('div',{staticClass:"features"},[_vm._m(2),_c('van-image',{attrs:{"lazy-load":"","src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/features-en.jpg"}})],1),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"logo",attrs:{"src":"//static.hoolihome.com/global-jwp/assets/m/fund/cyprus/cyprus-logo.png"}}),_c('p',{staticClass:"title"},[_c('span',[_vm._v("JWP AIF V.C.I.C. PLC ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("Investment Strategy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("Fund Features")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantages"},[_c('p',{staticClass:"title"},[_c('span',{staticClass:"van-hairline--bottom"},[_vm._v("JWP Fund Advantages")])]),_c('ul',{staticClass:"boxs"},[_c('li',{staticClass:"box"},[_c('p',{staticClass:"hd"},[_vm._v("Transparency")]),_c('p',{staticClass:"desc"},[_c('span',[_vm._v("Product information is clear")]),_c('span',[_vm._v("Regulated by the")]),_c('span',[_vm._v("ECB and CySEC， with complete")]),_c('span',[_vm._v("licenses, regular audit and")]),_c('span',[_vm._v("disclosure of investment")])])]),_c('li',{staticClass:"box"},[_c('p',{staticClass:"hd"},[_vm._v("Less fees")]),_c('p',{staticClass:"desc"},[_c('span',[_vm._v("No ownership cost")]),_c('span',[_vm._v("No income tax")]),_c('span',[_vm._v("No additional maintenance cost")])])]),_c('li',{staticClass:"box"},[_c('p',{staticClass:"hd"},[_vm._v("Spread risk")]),_c('p',{staticClass:"desc"},[_c('span',[_vm._v("Diversified allocation of geographical")]),_c('span',[_vm._v("location and asset types")]),_c('span',[_vm._v("Risk resistance")])])]),_c('li',{staticClass:"box"},[_c('p',{staticClass:"hd"},[_vm._v("Professional investment system")]),_c('p',{staticClass:"desc"},[_c('span',[_vm._v("Senior team makes more professional ")]),_c('span',[_vm._v("judgment for investment")]),_c('span',[_vm._v("Make plans for withdrawal path")]),_c('span',[_vm._v("simultaneously at investment stage")])])])])])
}]

export { render, staticRenderFns }